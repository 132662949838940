import React, { useState } from 'react';
import classes from '../Accounts/Accounts.module.scss';
import SearchEngine from '../../UI/SearchEngine/SearchEngine';
import { useQuery } from '@apollo/react-hooks';
import GroupingAccounts from '../../../queries/Accounts/GroupingAccounts';
import DraggableCard from '../Accounts/DraggableCard';
import Pagination from "react-js-pagination";
import { useMutation } from '@apollo/react-hooks';
import CreateGroupMutation from '../../../mutations/Groups/CreateGroup';
import GetGroupsQuery from '../../../queries/Groups/Groups';
import GetAccountsQuery from '../../../queries/Accounts/Accounts';
import CountAccounts from '../../../queries/Accounts/CountAccounts';
import NumberFormat from 'react-number-format';
import { useHistory } from "react-router-dom";

const AddGroup = (props) => {

    const title = "CREATE A USER GROUP";
    const history = useHistory();

    const [activePage, setActivePage] = useState(1);
    const [offset, setOffset] = useState((activePage - 1) * 7);
    const [totalItemsCount, setTotalItemsCount] = useState(0);
    const [selectAllInAccounts, setSelectAllInAccounts] = useState(false);
    const [filter_attributes, setFilterAttributes] = useState({ state: "active" });
    const [accounts, setAccounts] = useState([]);
    const [accountsIDList, setAccountsIDList] = useState([]);
    const [accountsNameList, setAccountsNameList] = useState([]);
    const accountsData = useQuery(GroupingAccounts, {
        variables: { offset: offset, limit: 7, attributes: filter_attributes }, onCompleted: () => {
            if (!accountsData.error && accountsData.data) {
                setAccounts(accountsData.data.users)
                //checks if all accounts without a group are selected
                const accountsWithoutGroup = accountsData.data.users.filter(account => account.group === null);
                let found = true;
                if (accountsWithoutGroup.length === 0) found = false;
                accountsWithoutGroup.forEach(account => {
                    if (!accountsIDList.includes(account.id)) found = false;
                });
                setSelectAllInAccounts(found);
            }
        }
    });

    const accounts_counter = useQuery(CountAccounts, {
        variables: { attributes: filter_attributes }, onCompleted: () => {
            if (!accounts_counter.error && accounts_counter.data) {
                setTotalItemsCount(accounts_counter.data.countAccounts)
            }
        }
    });
    const [writeGroupName, setWriteGroupName] = useState(false);
    const [groupName, setGroupName] = useState("");
    const [groupLimit, setGroupLimit] = useState("");
    const [message, setMessage] = useState(null);
    const [createGroupMutation] = useMutation(CreateGroupMutation, {
        refetchQueries: [{ query: GetGroupsQuery, variables: { offset: 0, limit: 7, group_name: null } }, { query: GetAccountsQuery, variables: { offset: 0, limit: 7, grouped: false, name: null } }],
    });

    const onClickHandle = () => {
        setWriteGroupName(true);
    }
    const onChangeNameHandle = (event) => {
        setGroupName(event.target.value);
    }

    const onChangeLimitHandle = (value) => {
        setGroupLimit(value);
    }


    const toggleUserGroupIcon = () => {
        history.push({
            pathname: '/dashboard/accounts',
            state: { view: "showAccounts" }
        })
    }

    const onCancel = () => {
        history.push({
            pathname: '/dashboard/accounts',
            state: { view: "showGroups" }
        })
    }

    const toggleSelectAllAccounts = () => {

        const temp = accountsIDList;
        const tempNamesList = accountsNameList;

        setSelectAllInAccounts(!selectAllInAccounts);
        if (!selectAllInAccounts) {//select all accounts
            accounts.forEach(account => {
                if (account.group === null && !temp.includes(account.id)) {
                    temp.push(account.id);
                    tempNamesList.push({
                        id: account.id,
                        commercialName: account.globalName
                    })
                }
            });
        }
        else {//remove accounts
            const temp = accountsIDList;
            const accountsWithoutGroup = accounts.filter(account => account.group === null);
            accountsWithoutGroup.forEach((account) => {
                if (temp.includes(account.id)) {
                    const index = temp.indexOf(account.id);
                    if (index > -1) {
                        temp.splice(index, 1);
                    }
                }

                const index = tempNamesList.map(user => user.id).indexOf(account.id);
                if (index > -1) {
                    tempNamesList.splice(index, 1);
                }
            })
        }

        setAccountsIDList([...temp]);
        setAccountsNameList([...tempNamesList]);

    }

    const toggleCardSelection = (accountID) => {
        const temp = accountsIDList;
        const tempNamesList = accountsNameList;
        //if the ID is on the list, remove it
        if (temp.includes(accountID)) {
            let index = temp.indexOf(accountID);
            if (index > -1) {
                temp.splice(index, 1);
            }
            index = tempNamesList.map(user => user.id).indexOf(accountID);
            if (index > -1) {
                tempNamesList.splice(index, 1);
            }
        }//otherwise add the id on the list
        else {
            temp.push(accountID);
            const accountTemp = accounts.filter(account => account.id === accountID)[0];
            tempNamesList.push({
                id: accountTemp.id,
                commercialName: accountTemp.globalName
            });
        }

        setAccountsIDList([...temp]);

        //checks if all accounts without a group are selected
        const accountsWithoutGroup = accounts.filter(account => account.group === null);
        let found = true;
        accountsWithoutGroup.forEach(account => {
            if (!temp.includes(account.id)) found = false;
        });
        setSelectAllInAccounts(found);
    }

    const filterSearchBox = (value) => {

        if (value === "") {
            setFilterAttributes({ ...filter_attributes, accountName: null })
        }
        else {
            setFilterAttributes({ ...filter_attributes, accountName: value })
        }

    }

    const onSave = () => {


        if (groupName === null || groupName === "" || groupLimit === null || groupLimit === "") {
            setMessage("Please enter both Group Name and Group Limit");
        }
        else if (accountsIDList.length === 0) {
            setMessage("Please add accounts to the group");
        }
        else {
            createGroupMutation(
                {
                    variables:
                    {
                        users: accountsIDList,
                        limit: groupLimit,
                        name: groupName
                    }
                }).then(result => {
                    history.push({
                        pathname: '/dashboard/accounts',
                        state: { view: "showGroups" }
                    })
                }).catch(error => {
                    if (error.message === "GraphQL error: This group name already exists") {
                        setMessage("This group name already exists");
                    }
                    else if (error.message === "GraphQL error: Invalid input: Name has already been taken") {
                        setMessage("This group name already exists");
                    }
                });
        }
    }

    const handlePageChange = pageNumber => {
        setActivePage(pageNumber);
        setOffset((pageNumber - 1) * 7);
        const scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'smooth'
        }
        window.scrollTo(scrollOptions);
    };

    let accountCards = accounts.map((account, index) => {

        return <DraggableCard key={account.id} account={account} selected={accountsIDList.includes(account.id)} action="add" toggleCardSelection={toggleCardSelection} />
    });

    let nameListHTML = accountsNameList.map(account => <li key={account.id}>{account.commercialName ? account.commercialName : account.firstName + " " + account.lastName}</li>)

    return (
        <div className={classes.Page}>
            <div className={classes.PageColumn}>
                <div className={classes.Title}>
                    <span>{title}</span>

                    <span style={{ marginLeft: "auto", color: "#fd6700", fontSize: 23, opacity: 0.3 }} onClick={toggleUserGroupIcon}>
                        <i className="fas fa-users"></i>
                    </span>
                </div>

                <div className={classes.SelectCircle} style={selectAllInAccounts ? { background: "#58595b" } : null} onClick={toggleSelectAllAccounts}></div>

                <SearchEngine placeholder='Search for an account' extraStyle={{ margin: "30px 0" }} filter_search_filed={filterSearchBox} value={filter_attributes.accountName || null} />

                {accountCards}

                <div className={classes.Pagination} >
                    <div className={classes.order_pagination}>
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={7}
                            totalItemsCount={totalItemsCount}
                            pageRangeDisplayed={9}
                            prevPageText={null}
                            nextPageText={null}
                            activeLinkClass={classes.activePage}
                            itemClass={classes.numbers}
                            linkClass={classes.numbers}
                            onChange={handlePageChange}
                        />
                    </div>
                </div>
            </div>

            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <div className={classes.AddGroup}>
                    <div className={classes.Title}>
                        {!writeGroupName ? <div onClick={onClickHandle}>NAME YOUR GROUP <span style={{ color: "#fd6700" }}>HERE</span></div> :
                            <input className={classes.GroupName} type="text" placeholder="Write Group Name" value={groupName} onChange={onChangeNameHandle} />}

                        <button style={{ marginLeft: "auto" }} className={classes.YellowButton} onClick={onSave}>SAVE</button>
                    </div>
                    <div style={{ display: "flex", alignItems: "flex-end", marginTop: 12 }}>
                        <NumberFormat className={classes.GroupName} placeholder="Write Group Limit" value={groupLimit} thousandSeparator={true} onValueChange={(values) => onChangeLimitHandle(values.value)} />
                        <button style={{ marginLeft: "auto" }} className={classes.WhiteButton} onClick={onCancel}>CANCEL</button>
                    </div>
                    {message !== null && <div style={{ marginTop: 10, color: "#9A2020" }}>{message}</div>}
                    <div style={{ marginTop: 32 }}>
                        <span style={{ fontSize: 20 }}><b>
                            Select the accounts you want to add to your group. <br />
                        </b></span>

                    </div>
                    <div style={{ marginTop: 20 }}><b>Members of this group:</b></div>
                    <ol style={{ fontWeight: "bold" }}>{nameListHTML}</ol>
                </div>
            </div >
        </div>
    )
}

export default AddGroup;
