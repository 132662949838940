import React, { Component } from 'react';
import classes from './Documents.module.scss';
import { getFileMetadata, directUpload } from "../../../services/fileUpload";
import create_direct_upload from '../../../mutations/FileUpload/UploadFile';
import upload_registration_document from '../../../mutations/FileUpload/UploadRegistrationDocument';
import compose from 'lodash/fp/compose';
import { graphql } from 'react-apollo';

class Documents extends Component {
  constructor(props) {
    super(props);
    this.myInput = React.createRef();
    this.state = {
      typology: null,
      contract_filename: null,
      privacy_policy_filename: null,
      terms_conditions_filename: null,
      signed_id_contract: null,
      signed_id_policy_privacy: null,
      signed_id_terms_conditions: null
    };
  }

  createDocument = () => {
    this.props.uploadRegistrationDocument({
      variables: {
        typology: this.state.typology,
        signed_blob_id: eval("this.state.signed_id_" + this.state.typology)
      },
    }).then(res => {

    }).catch(error => {
      console.log(error)
    })
  }

  handleClick = (typology) => {
    this.myInput.current.click();
    this.setState({ typology })
  }


  uploadFile = (file, typology) => {
    getFileMetadata(file).then((input) => {
      return this.props.createDirectUpload({ variables: { input: input } }).then(({ data }) => {
        const uploadData = data.directUpload;
        const typology = this.state.typology

        this.setState({
          [typology]: uploadData.filename,
          ["signed_id_" + typology]: uploadData.signedBlobId
        })
        return directUpload(uploadData.url, JSON.parse(uploadData.headers), file).then(
          this.createDocument()
        )
      });
    });
  }

  render() {

    return (
      <div className={classes.Page}>
        <div className={classes.PageColumn} style={{ maxWidth: 790 }}>
          <div className={classes.Title}>
            <span>CURRENT REGISTRATION DOCUMENTS</span>
          </div>
          <input type="file" id="file" ref={this.myInput} onChange={(e) => e.target.files[0] ? this.uploadFile(e.target.files[0]) : null} style={{ display: "none" }} />

          <div className={classes.UploadButtons}>
            <div>
              <div className={classes.box} style={{ cursor: "pointer" }} onClick={() => this.handleClick("contract")}> <i className="fas fa-arrow-circle-up"></i> <span>CONTRACT</span></div>
              {this.state.contract ? <Filename filename={this.state.contract} /> : null}
            </div>
            <div>
              <div className={classes.box} style={{ cursor: "pointer" }} onClick={() => this.handleClick("privacy_policy")}> <i className="fas fa-arrow-circle-up"></i> <span>PRIVACY POLICY</span></div>
              {this.state.privacy_policy ? <Filename filename={this.state.privacy_policy} /> : null}
            </div>
            <div>
              <div className={classes.box} style={{ cursor: "pointer" }} onClick={() => this.handleClick("terms_conditions")}> <i className="fas fa-arrow-circle-up"></i> <span>TERMS & CONDITIONS</span></div>
              {this.state.terms_conditions ? <Filename filename={this.state.terms_conditions} /> : null}
            </div>
          </div>

        </div>
      </div>
    )
  };

}

const Filename = (props) =>
  <div style={{ color: "#fd6700", display: "flex", alignItems: "center", justifyContent: "center", marginTop: "5px" }}>
    <i className="fas fa-check" style={{ fontSize: "15px", marginRight: "5px" }} />
    <div style={{ fontSize: "10px" }}>{props.filename}</div>
  </div>

export default compose(
  graphql(upload_registration_document, { name: 'uploadRegistrationDocument' }),
  graphql(create_direct_upload, { name: 'createDirectUpload' })
)(Documents);
