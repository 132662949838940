import React, { Component } from 'react';
import classes from './FilterModal.module.scss';

class FilterModal extends Component {
    constructor(props) {
        super(props);
        const default_values = this.props.default_values
        this.state = {
            min_paid: default_values ? default_values.min_paid : "",
            max_paid: default_values ? default_values.max_paid : "",
            min_rate: default_values ? default_values.min_rate : "",
            max_rate: default_values ? default_values.max_rate : "",
            min_value: default_values ? default_values.min_value : "",
            max_value: default_values ? default_values.max_value : "",
            currencies: default_values ? default_values.currencies : [
                { value: "EUR", name: "EUR (EUROS)", sign: "€", isChecked: false },
                { value: "USD", name: "USD (US dollars)", sign: "$", isChecked: false },
                { value: "GBP", name: "GBP (British pounds)", sign: "£", isChecked: false },
                { value: "CHF", name: "CHF (Swiss francs)", sign: "₣", isChecked: false }
            ]
        }

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
    * close modal if clicked on outside of element
    */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.srcElement.offsetParent.className !== 'filterModalIcon') {
            this.props.closeModal();
        }
    }

    childEvent(event) {
        event.stopPropagation();
        if (this.props.childEvent) {
            this.props.childEvent();
        }
    }

    handleClickCurrency = (currency_value) => {
        let currencies = [...this.state.currencies]

        currencies.forEach(currency => {
            if (currency.value === currency_value) {
                currency.isChecked = !currency.isChecked
            }
        })
        this.setState({
            currencies: currencies
        })
    }

    onSaveFilters = () => {
        this.props.closeModal()
        this.props.filterRecords(this.state)
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    resetFilters = () => {
        let currencies = [...this.state.currencies]
        currencies.forEach(currency => {
            currency.isChecked = false
        })

        this.setState({
            currencies: currencies,
            min_paid: "",
            max_paid: "",
            min_rate: "",
            max_rate: "",
            min_value: "",
            max_value: "",
        })
    }

    render() {
        return (
            <div ref={this.setWrapperRef} className={[classes.ModalItem, classes.FilterModal].join(' ')} onClick={this.childEvent.bind(this)} >
                <div className={classes.ModalHeader}>
                    <div className={classes.FilterModalHeader}>FILTERS</div>
                    <div className={classes.FilterModalHeaderIcon}>
                        <i className="fas fa-undo-alt" style={{ color: "white", fontSize: "23px", marginRight: "10px", cursor: "pointer" }} onClick={this.resetFilters}></i>
                    </div>
                </div>

                <div className={[classes.ModalBody, classes.FilterModalBody].join(' ')}>
                    <div className={classes.FilterItem}>
                        <div className={classes.ItemHeader}> <p className={classes.ItemTitle}>Currency</p> </div>
                        <div className={classes.Currencies}>
                            {
                                this.state.currencies.map(currency => {
                                    const currency_style = currency.isChecked ?
                                        {
                                            background: "#58595b",
                                            color: "white"
                                        }
                                        :
                                        {
                                            backround: "none",
                                            color: "#58595b"
                                        }
                                    return <div key={currency.value} style={currency_style} className={classes.Currency} onClick={() => this.handleClickCurrency(currency.value)}>{currency.sign}</div>
                                })
                            }
                        </div>

                    </div>
                    <div className={classes.FilterItem}>
                        <div className={classes.ItemHeader}> <p className={classes.ItemTitle}>Value</p></div>
                        <div className={classes.Currencies}>
                            <input type="text" className={classes.Inputs} placeholder="Min" name="min_value" value={this.state.min_value} onChange={this.handleInput} />
                            <input type="text" className={classes.Inputs} placeholder="Max" name="max_value" value={this.state.max_value} onChange={this.handleInput} />
                        </div>
                    </div>
                    <div className={classes.FilterItem}>
                        <div className={classes.ItemHeader}>
                            <p className={classes.ItemTitle}>Price paid (L)</p>
                        </div>
                        <div className={classes.Currencies}>
                            <input type="text" className={classes.Inputs} placeholder="Min" name="min_paid" value={this.state.min_paid} onChange={this.handleInput} />
                            <input type="text" className={classes.Inputs} placeholder="Max" name="max_paid" value={this.state.max_paid} onChange={this.handleInput} />
                        </div>
                    </div>
                    <div className={classes.FilterItem}>
                        <div className={classes.ItemHeader}> <p className={classes.ItemTitle}>Rate</p>  </div>
                        <div className={classes.Currencies}>
                            <input type="text" className={classes.Inputs} placeholder="Min" name="min_rate" value={this.state.min_rate} onChange={this.handleInput} />
                            <input type="text" className={classes.Inputs} placeholder="Max" name="max_rate" value={this.state.max_rate} onChange={this.handleInput} />
                        </div>
                    </div>

                    <div className={classes.CenterButtons}>
                        <button className={classes.YellowButton} onClick={() => this.onSaveFilters()}>SAVE</button>
                        <button className={classes.WhiteButton} onClick={() => this.props.closeModal()}> CANCEL </button>
                    </div>
                </div>
            </div>
        );
    }
}
export default FilterModal;
