import React from 'react';
import classes from './Accounts.module.scss';
import { useMutation } from '@apollo/react-hooks';
import ApproveUser from "../../../mutations/UserManagement/ApproveUser";
import DisableUser from "../../../mutations/UserManagement/DisableUser";
import GetAccountsQuery from '../../../queries/Accounts/Accounts';
import NumberFormat from 'react-number-format';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const Card = (props) => {
    var dateFormat = require('dateformat');

    const account = props.account;
    const group = props.group;

    const item = (account !== undefined ? account : group)
    const index = props.index;
    const accountSelectedIndex = props.accountSelectedIndex;
    let cardStyle = {
        boxShadow: "none",
        border: "0.5px solid #CCCCCC"
    }

    if (index === accountSelectedIndex) {
        cardStyle = {
            boxShadow: "0px 3px 6px #00000029",
            border: "0.5px solid #58595b"
        }
    }

    if (props.account && props.account.state === "ready_for_activation") {
        cardStyle = {
            boxShadow: "0px 3px 6px #00000029",
            border: "0.5px solid #fd6700"
        }
    }

    if (props.action === null) {
        cardStyle.cursor = "default";
    }

    const [approveUser] = useMutation(ApproveUser, { refetchQueries: [{ query: GetAccountsQuery }] });
    const handleApproveUser = (event) => {
        event.stopPropagation();
        approveUser(
            {
                variables:
                {
                    user_id: account.id
                }
            }).then(result => console.log("Success")).catch(error => console.log(error));
    }

    const [disableUser] = useMutation(DisableUser, { refetchQueries: [{ query: GetAccountsQuery }], });
    const handleDeleteUser = (event) => {
        event.stopPropagation();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className={classes.CustomConfirmationDialog}>
                        <h3>Are you sure you want to delete this user?</h3>
                        <button className={classes.WhiteButton} onClick={onClose}>No</button>
                        <button className={classes.YellowButton}
                            onClick={() => {
                                deleteUser();
                                onClose();
                            }}
                        >
                            Yes
                  </button>
                    </div>
                );
            }
        });
    }

    const deleteUser = () => {
        disableUser(
            {
                variables:
                {
                    user_id: account.id
                }
            }).then(result => {
                props.onDeleteUser()
            }).catch(error => {
                console.log(error)
            });
    }

    const accountCard = (<>
        <div style={{ fontSize: 12 }}>
            <span>Since {account ? dateFormat(account.createdAt, "d/m/yyyy") : ""}</span>
            {(account && props.account.state !== "ready_for_activation") && (account.typology !== "market_viewer" ? <span style={{ float: "right" }}>Total Value</span> : null)}
        </div>
        <div style={{ fontSize: 20 }}>
            <span>
                {account && account.globalName}
            </span>
            <span style={{ float: "right" }}>
                {(account && props.account.state !== "ready_for_activation") ? (account.balances ? account.balances.filter(item => item.currency === 'ALL')[0].totalBalance : "") :
                    <> <span>
                        <i className="fas fa-check-square fa-2x" style={{ marginRight: 10, color: "#fd6700" }} onClick={(event) => handleApproveUser(event)}></i>
                        <i className="fas fa-times fa-2x" style={{ color: "#fd6700" }} onClick={(event) => handleDeleteUser(event)}></i>
                    </span></>}
            </span>
        </div>
        <div style={{ fontSize: 12, display: "flex" }}>
            <span>Group: {account && (account.group !== null && account.group !== undefined ? account.group.name : "-")}</span>
            <div style={{ display: "flex", marginLeft: "auto" }}>
                {(account && props.account.state !== "ready_for_activation") && (account.typology !== "market_viewer" ? <span>Daily Limit: {account && <NumberFormat
                    value={item.tradingDailyLimit}
                    displayType={'text'}
                    thousandSeparator=","
                    decimalSeparator="." />}</span> : null)}
            </div>
        </div></>);

    const groupCard = (<><div style={{ fontSize: 20, fontWeight: "bold" }}>
        <span>{group && group.name}</span><span style={{ float: "right" }}>{group && group.users.length} members</span></div></>);

    return (<>
        <div className={classes.AccountsCard}
            style={cardStyle}
            onClick={props.action !== null ? () => props.showDetails(props.index, props.cardType, item) : null}>
            {props.cardType === "accountCard" ? accountCard : groupCard}
        </div></>
    );
}

export default Card;
