import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import classes from "../Login.module.css";
import Button from "../../UI/Button/Button"
import Input from "../../UI/Input/Input"
import login_otp_mutation from "../../../mutations/Login/LoginOtp";
import sendOtp_mutation from '../../../mutations/Login/SendOtp';
import { graphql } from 'react-apollo';
import compose from 'lodash/fp/compose';
import query from '../../../queries/LoggedUser';
import back_office_users from '../../../queries/RoleManagement/BackOfficeUsers';


class CodeTool extends Component {

    state = {
        orderForm: {
            code: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Code',

                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6,
                    isNumeric: true
                },
                valid: false,
                touch: false
            }
        },
        listcode: ['1', '0', '6549-8745-3218'],
        formIsValid: false,
        message: <p style={{ color: "white" }}> </p>, //ORRIBILE
        resentOtp: false,
        correct: false,
        check: false,
    }


    checkValidity(value, rules) {
        let isValid = true;

        if (!rules) {
            return true;
        }

        this.setState({ touch: true });

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }
        //trim elimina gli spazi

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }


        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }
        return isValid
    }

    inputChangedHandler = (event) => {

        const updatedOrderForm = {
            ...this.state.orderForm
        };

        const updatedFormElement = this.state.orderForm.code

        updatedFormElement.value = event.target.value;
        //VALIDAZIONE
        if (this.state.orderForm.code.validation) {
            updatedFormElement.valid = this.checkValidity(this.state.orderForm.code.value, this.state.orderForm.code.validation)
        }

        updatedFormElement.touch = true;
        updatedOrderForm[this.state.orderForm.code] = updatedFormElement;

        let formIsValid = updatedFormElement.valid;

        this.setState({ orderForm: updatedOrderForm, formIsValid: formIsValid });

    }

    nextHandler = (otp) => {
        this.props.login_otp({
            variables: { otp },
            refetchQueries: [{ query: query }, { query: back_office_users }],
        }).then(res => {
            if (!res.data.loginOtp.success) {
                this.setState({ message: <p style={{ color: "#992020" }}>Invalid code</p> });
            }
            else {

                this.setState({ correct: true })
            }

        });
    }


    resendOtp = (otp) => {
        this.props.send_otp({
        }).then(res => {

            this.props.history.replace({
                pathname: '/login/code'
            });
            this.setState({ resendOtp: true })
        })


    }

    redirect_to_dashboard = () => {
        this.props.history.replace({
            pathname: "/welcome"
        });
    }

    render() {

        const code = this.state.orderForm.code;

        let form = (
            <form>
                <div className={classes.Components4}>
                    <div className={classes.Head}> </div>
                    <div className={classes.verificationContent}>
                        <p className={classes.verificationHeader}>Please enter your code</p>
                        {this.state.message}
                        <Input
                            inputStyle={classes.inputStyle}
                            autocomplete="off"
                            elementType={code.elementType}
                            elementConfig={code.elementConfig}
                            value={code.value}
                            invalid={!code.valid}
                            shouldValidate={code.validation}
                            touch={this.state.touch}
                            changed={(event) => this.inputChangedHandler(event)}
                            onKeyDown={e => {
                                if (e.key === "Enter") {
                                    this.nextHandler(code.value);
                                    e.preventDefault();
                                }
                            }}
                        />
                        <p className={classes.forgotPasswordText}>Didn't receive a code?<a style={{ color: "#fd6700", cursor: "pointer" }} onClick={this.resendOtp}> Click here.</a></p>

                        {this.state.resendOtp ?
                            <p className={classes.forgotPasswordText}><NavLink to={"verification"}>The code was resent to your email</NavLink></p>
                            :
                            null
                        }

                        {!this.state.correct ? <Button
                            clicked={() => this.nextHandler(code.value)}
                            extraStyle={"loginButton"}
                        > VERIFY </Button>
                            :
                            <Button
                                clicked={() => this.redirect_to_dashboard()}
                                extraStyle={"loginButton"}
                            > NEXT </Button>
                        }
                    </div>
                </div>


            </form>
        );

        return (
            <div>
                {form}
            </div>
        );
    }
}

export default compose(
    graphql(login_otp_mutation, { name: 'login_otp' }),
    graphql(sendOtp_mutation, { name: 'send_otp' })
)(CodeTool);
