import React, { useState } from 'react';
import classes from './MainPage.module.scss';
import UserLogInfo from './UserLogInfo';
import UserInfo from './UserInfo';
import STAConfirmationPopup from './STAConfimationPopup';
import { useMutation } from 'react-apollo';
import DeleteBackOfficeUser from '../../../mutations/RoleManagement/DeleteBackOfficeUser';
import BackOfficeUsersQuery from '../../../queries/RoleManagement/BackOfficeUsers';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const AllUsers = (props) => {

    const users = props.users;
    const currentUser = props.currentUser;
    const [selectedUser, setSelectedUser] = useState("");
    const [showLogInfo, setShowLogInfo] = useState(false);
    const [showUserInfo, setShowUserInfo] = useState(false);
    const [user, setUser] = useState(null);

    const [deleteBackOfficeUser] = useMutation(DeleteBackOfficeUser, { refetchQueries: [{ query: BackOfficeUsersQuery }] });

    const toggleShowLogInfo = (user) => {
        setShowLogInfo(!showLogInfo);
        setUser(user);
    }

    const toggleShowUserInfo = (user) => {
        setShowUserInfo(!showUserInfo);
    }

    const handleOnChange = (event) => {
        setSelectedUser(event.target.value);
    }

    const selectUser = (item) => {
        props.select(item);
        setUser(item);
        setShowUserInfo(true);
    }

    const handleDeleteUser = (user) => {
        let fullControl = currentUser.fullControl;
        let fullControlDate = new Date(fullControl);
        // let hours = Math.abs(fullControlDate - new Date()) / 36e5;
        let hours = (fullControlDate - new Date()) / 36e5;
        if (user.role.id === "1" && (currentUser.fullControl === null || hours < 0)) {
            props.toggleSTAConfirmationPopup();
        }
        else {

            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className={classes.CustomConfirmationDialog}>
                            <h3>Are you sure you want to delete this user?</h3>
                            <button className={classes.WhiteButton} onClick={onClose}>No</button>
                            <button className={classes.YellowButton}
                                onClick={() => {
                                    deleteUser(user.id);
                                    onClose();
                                }}
                            >
                                Yes
                      </button>
                        </div>
                    );
                }
            });
        }
    }

    const deleteUser = (userId) => {
        deleteBackOfficeUser({
            variables: {
                user_id: parseInt(userId)
            }
        });
    }

    const regex = new RegExp(selectedUser, "i");

    return (<div className={classes.AllUsers}>
        <div className={classes.SearchBar}>
            <i className={["fas fa-search fa-lg", classes.SearchIcon].join(' ')}></i>
            <input
                className={classes.SearchInput}
                type="text"
                value={selectedUser}
                onChange={handleOnChange}
            />

            {!showLogInfo && !showUserInfo ?
                <div style={{ display: 'flex' }}
                    className={classes.SearchItems}>
                    {users && users.filter((item) => item.firstName.search(regex) === 0 || item.lastName.search(regex) === 0).map(item =>
                        <div key={item.id} className={classes.Item}>
                            <div className={classes.SelectionCircle} style={props.selectedUser && (props.selectedUser.id === item.id ? { background: "#fd6700" } : null)}
                                onClick={() => props.select(item)}>
                            </div>
                            <div style={{ marginLeft: "20px", flex: "1 1 2%" }} onClick={() => selectUser(item)}><b>{item.firstName + " " + item.lastName}</b></div>
                            <div style={{ flex: "1 1 7%" }}>{item.role.name}</div>
                            <div style={{ flex: "0 1 5%" }} onClick={() => toggleShowLogInfo(item)}>
                              <i className="fas fa-clipboard-list" style={{ color: "#fd6700" }}></i>
                            </div>
                            {currentUser.role.name === "super technical admin" ?
                                <div style={{ flex: "-1 1 5%" }} onClick={() => handleDeleteUser(item)}><i className="fas fa-trash" style={{ color: "#fd6700" }} ></i></div> : null}
                            {currentUser.role.name !== "super technical admin" && item.role.name !== "super technical admin" ?
                                <div style={{ flex: "-1 1 5%" }} onClick={() => handleDeleteUser(item)}><i className="fas fa-trash" style={{ color: "#fd6700" }} ></i></div> : null}
                        </div>)}
                </div> : null}
        </div>
        {showLogInfo && <UserLogInfo user={user} toggleShowLogInfo={toggleShowLogInfo} />}
        {showUserInfo && <UserInfo user={user} toggleShowUserInfo={toggleShowUserInfo} />}
        {props.showSTAConfirmationPopup && <STAConfirmationPopup users={users} toggleSTAConfirmationPopup={props.toggleSTAConfirmationPopup} />}
    </div>);
}

export default AllUsers;
