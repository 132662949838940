import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classes from './Login.module.scss';
import login_mutation from "../../mutations/Login/Login"
import sendOtp_mutation from "../../mutations/Login/SendOtp";
import compose from 'lodash/fp/compose';
import Input from "../UI/Input/Input";
import { graphql } from 'react-apollo';
import query from "../../queries/LoggedUser"
import logo from '../../assets/images/union-bank-logo.png';

class LoginTool extends Component {
    constructor(props) {
        super(props);
        this.is_mounted = false;
    }

    state = {
        orderForm: {
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'Email'
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touch: false,

            },

            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Password'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6,
                    maxLength: 15
                },
                valid: false,
                touch: false,

            }
        },
        formIsValid: false,
        message: <p>{'\u00A0'}</p>,

        data: null,
    }


    checkValidity(value, rules) {
        let isValid = true;

        if (!rules) {
            return true;
        }

        this.setState({ touch: true });

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
            //trim elimina gli spazi
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        return isValid
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedOrderForm = {
            ...this.state.orderForm
        };
        const updatedFormElement = {
            ...updatedOrderForm[inputIdentifier]
        };

        updatedFormElement.value = event.target.value;
        //VALIDAZIONE
        if (updatedFormElement.validation) {
            updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation)
        }

        updatedFormElement.touch = true;
        updatedOrderForm[inputIdentifier] = updatedFormElement;

        let formIsValid = true;

        for (let inputIdentifier in updatedOrderForm) {
            formIsValid = updatedOrderForm[inputIdentifier].valid && formIsValid && updatedOrderForm[inputIdentifier].touch
        }

        this.setState({ orderForm: updatedOrderForm, formIsValid: formIsValid });
    }

    onLoginClick = (email, password) => {
        this.props.login({
            variables: { email, password },
        }).then(res => {
            if (res.data.login == null) {
                this.setState({ message: <p style={{ color: "#992020" }}>Email or password is incorrect</p> });
            }

            else {
                const token = res.data.login.token;
                localStorage.setItem("token", token);
                this.props.send_otp({
                }).then(res => {
                    this.props.history.replace({
                        pathname: '/login/code'
                    });
                })
            }
        });
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.onLoginClick(this.state.orderForm.email.value, this.state.orderForm.password.value)
        }
    }

    render() {
        const formElementsArray = [];
        for (let key in this.state.orderForm) {
            formElementsArray.push({
                id: key,
                config: this.state.orderForm[key] //email,password
            });
        }

        let form = (
            <form>
                <div className={classes.content}>
                    {formElementsArray.map(formElement => (
                        <Input
                            key={formElement.id}
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            value={formElement.config.value}
                            invalid={!formElement.config.valid}
                            shouldValidate={formElement.config.validation}
                            touch={formElement.config.touch}
                            changed={(event) => this.inputChangedHandler(event, formElement.id)}
                            inputStyle={classes.inputs}
                            onKeyDown={this._handleKeyDown}
                            autocorrect="off"
                            autocapitalize="off"
                            spellcheck="false"
                        />
                    ))}
                </div>
            </form>
        );

        return (
            <div className={classes.Card}>
                <div className={classes.CardHeader}> </div>
                <div className={classes.CardContent}>
                    <img src={logo} alt="Union Bank Logo" style={{margin:"10px auto"}}/>
                    <div className={classes.WelcomeText}>Welcome to<br />Union Change Back Office</div>
                    <div className={classes.SubText}>Please login</div>
                    {this.state.message}
                    {form}
                    <p className={classes.ForgotPasswordText}>Forget your password? <Link to={"/login/forgetPassword"}>Click here</Link>.</p>
                    <button className={classes.YellowButton}
                        onClick={() => this.onLoginClick(this.state.orderForm.email.value, this.state.orderForm.password.value)}>LOG IN</button>
                </div>
            </div>
        );
    };
}


export default graphql(query)(compose(
    graphql(login_mutation, { name: 'login' }),
    graphql(sendOtp_mutation, { name: 'send_otp' })
)(LoginTool));
