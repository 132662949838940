import React, { useState, useEffect} from 'react';
import classes from './IncomingPayments.module.scss';
import IntesaSanPaoloIcon from '../../../assets/icons/ChartsIcons/Intesa San Paolo.png';
import RaiffeisenIcon from '../../../assets/icons/ChartsIcons/Raiffeisen.png';
import BankIcon from './BankIcon';
import NumberFormat from 'react-number-format';

const DetailsActionCard = (props) => {
  const [missingValue, setMissingValue] = useState(null);
  let [markAsDefaultedDisable, setMarkAsDefaultedDisable] = useState(false);
  const [selectedDueList, setselectedDueList] = useState({})

  useEffect(() => {

    var item = {}

    for (const [key, value] of Object.entries(props.selectedDue)) {
      let newValue = value.values.filter(x=>x.CI == false).filter(x=>x.paidToPortal == false)
      if (newValue.length > 0) {
        item[key] = {bank:key, values:newValue}
      }
    }

    setselectedDueList(item)
    if (!props.markAsPaidDisable) {
      document.getElementById('defaulted_ID').disabled = false
      document.getElementById('defaulted_ID').classList.remove(classes.ButtonDisabled)
    }
  }, [props.selectedDue]);

  // console.log(selectedDueList);

  const addMissingValue = (value) => {
    if (Object.keys(props.selectedItems).length == 1) {
      document.getElementById('defaulted_ID').setAttribute('missing_payment',Math.abs(value))
    }
    else{
      if (value != null) {
        document.getElementById('defaulted_ID').disabled = true
        document.getElementById('defaulted_ID').classList.add(classes.ButtonDisabled)
      }
      else{
        document.getElementById('defaulted_ID').disabled = false
        document.getElementById('defaulted_ID').classList.remove(classes.ButtonDisabled)
      }
      document.getElementById('defaulted_ID').setAttribute('missing_payment',null)
    }
  }

  const BankRow = (props) => {
    var currencies = {};
    var list = props.bank.values.map((index, key)=>{
      if (index.type == "purchase") {
        if (currencies.hasOwnProperty(index.amountPaidCurrency)){
          currencies[index.amountPaidCurrency].value += parseFloat(index.amountPaidCents.replaceAll(" ","").replace(/\D/, "").replaceAll(",",''))
        }else{
          currencies[index.amountPaidCurrency] = {currency: index.amountPaidCurrency, value: parseFloat(index.amountPaidCents.replaceAll(" ","").replace(/\D/, "").replaceAll(",",''))};
        }

      }else{
        if (currencies.hasOwnProperty(index.amountSoldCurrency)){
          currencies[index.amountSoldCurrency].value += parseFloat(index.amountSoldCents.replaceAll(" ","").replace(/\D/, "").replaceAll(",",''))
        }else{
          currencies[index.amountSoldCurrency] = {currency: index.amountSoldCurrency, value: parseFloat(index.amountSoldCents.replaceAll(" ","").replace(/\D/, "").replaceAll(",",''))};
        }

      }

    })
    var currencies_list = Object.values(currencies).map((index, key)=>{
      var inputValue = 0;
      return (
        <div key={key} className={classes.BankRowBody}>

          {/* <span style={{width:50}}>{inputValue >= index.value ? "OK" : "No" }</span> */}
          <CheckDue data={index} addMissingValue={addMissingValue}/>
        </div>
      )
    })
    return(
      <div className={classes.BankRow}>
        <div className={classes.BankRowHead}>
          <span style={{bottom:1, marginRight:5}}>
            <BankIcon bank={{bank_name:props.bank.values[0].bankAccounts[0].bankName}} square={true}/>
          </span>
          <span>{props.bank.values[0].bankAccounts[0].bankName}</span>
        </div>
        {currencies_list}
      </div>
    )
  }

  var BankRowList = Object.values(selectedDueList).map((index, key)=>{
    console.log(index);
    if (index.values.length == 0) {
      return
    }
    return (<BankRow key={key} bank={index} addMissingValue={addMissingValue}/>)
  })

  var paidStyle = props.markAsPaidDisable ? ' ' + classes.ButtonDisabled : '';
  var unpaidStyle = props.markAsUnpaidDisable ? ' ' + classes.ButtonDisabled : '';
  var defaultedStyle = paidStyle;
  var disabledDefaulted = props.markAsPaidDisable;

  if (markAsDefaultedDisable && props.markAsPaidDisable == false) {
    defaultedStyle = ' ' + classes.ButtonDisabled
    // disabledDefaulted =
  }

  return (
    <div className={classes.DetailsActionCard}>
      <div style={{ margin: "20px 0" }}>
        <div style={{ marginBottom: 5 }}><b>AMOUNT DUE: </b></div>
        <div>Select one or more pending payments to see the total amount due</div>
      </div>
      {BankRowList}

      <div style={{ margin: "20px 0" }}>
        <div style={{ marginBottom: 5 }}><b>MAKE A PAYMENT: </b></div>
        <div>Take actions on all of the selected payments using the options bellow</div>
      </div>
      {props.markAsPaidDisplay ?
        <div style={{ margin: "20px 0", height:40 }}>
          <button
            className={classes.YellowButton + paidStyle}
            style={{ width: 180, float: "left", marginLeft: 0, marginRight:10}}
            disabled={props.markAsPaidDisable}
            onClick={()=>{props.markAsPaid()}}
            >MARK AS PAID</button>
            <button className={classes.YellowButton + paidStyle} disabled={props.markAsPaidDisable} style={{ width: 180, float: "left", marginLeft: 0, marginRight:10}} onClick={() => props.sendNotice()}>SEND NOTICE</button>
            <button className={classes.RedButton + defaultedStyle} id={'defaulted_ID'} disabled={disabledDefaulted} style={{ width: 180, float: "left", marginLeft: 0}} onClick={() => props.sendDefault()}>DEFAULT(CI)</button>
          </div> : null }
          {props.markAsUnpaidDisplay ?
            <div style={{ margin: "20px 0", height:40 }}>
              <button
                className={classes.WhiteButton + unpaidStyle}
                style={{ width: 180, float: "left", marginLeft: 0, marginRight:10, marginTop:10, marginBottom:10}}
                disabled={props.markAsUnpaidDisable}
                onClick={()=>{props.markAsPaid(false)}}
                >MARK AS UNPAID</button></div> : null}

                {props.guarantee.display ?
                <div style={{ margin: "40px 0" }}>
                  <div style={{ marginBottom: 5 }}><b>GUARANTEE: </b></div>
                  <div>
                    {props.guarantee.user.globalName}
                  's current guarantee is <NumberFormat value={props.guarantee.user.guarantee} displayType={'text'} thousandSeparator="," decimalSeparator="." /></div>
                </div>
                : null}
                <div style={{ margin: "20px 0",height:40, float:'left', width:'100%' }}>
                    <button
                      className={classes.YellowButton}
                      style={{ width: 180, float: "left", marginLeft: 0, marginRight:10}}
                      onClick={()=>{props.setState.setIsMatching(!props.state.isMatching)}}
                      >{props.state.isMatching ? 'HIDE MATCHES' :'VIEW MATCHES'}</button>
                    </div>
                  </div>
              );
              function viewMatches() {
                props.viewMatches();
              }
            }

export default DetailsActionCard;

const CheckDue = (props) => {
  var data = props.data;
  const [initialValue, setInitialValue] = useState(data.value);
  const [calculationDisplay, setCalculationDisplay] = useState(false);
  const [calculationDiff, setCalculationDiff] = useState(false);

  const Value = () => {
    if (calculationDiff < 0) {
      return(<span style={{color:'#9A2020', fontSize:15, fontWeight:'bold'}}>{calculationDiff.toFixed(2).toString().replaceAll(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</span>)
    }else if (calculationDiff > 0) {
      return(<span style={{color:'#006A32', fontSize:15, fontWeight:'bold'}}>+{calculationDiff.toFixed(2).toString().replaceAll(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</span>)
    }
    else{
      return(<span><i className="fas fa-check"></i> <span style={{marginLeft:5,fontSize:15, fontWeight:'bold', color:'#58595b'}}>OK</span></span>)
    }
  }
  return(
    <>
      <span className={classes.BankRowBodyCurrency}>{data.currency}</span>
      <span className={classes.BankRowBodyValue}>{data.value.toFixed(2).toString().replaceAll(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</span>
      <span className={classes.BankRowBodyCurrency}>{data.currency}</span>
      <span className={classes.BankRowBodyValue}>
        <NumberFormat style={{width:'100%'}} thousandSeparator={true} onKeyUp={(value)=>{calculate(value)}}/>
      </span>
      {calculationDisplay ? <Value /> : null}
    </>
  )
  function calculate(event) {
    // if(event.key === 'Enter'){
      var value = event.target.value.replaceAll(",","");
      if (value) {
        setCalculationDiff(parseFloat(value) - initialValue);
        setCalculationDisplay(true);
        props.addMissingValue(parseFloat(value) - initialValue);
      }else{
        setCalculationDisplay(false);
        props.addMissingValue(null);
      }
    // }
  }
}
