import React, { useState, useEffect } from 'react';
import classes from '../Accounts.module.scss';

const PrivateAccount = (props) => {

    var dateFormat = require('dateformat');

    const [account, setAccount] = useState(props.account);
    useEffect(() => {
        setAccount(props.account);
    }, [props.account])

    return (
        <div className={classes.UserInfo}>
            <span><b>Account Type: </b>Private Account</span>

            <div className={classes.Content}>
                <div style={{ width: "100%" }}>
                    <div className={classes.Section1}>
                        <div className={classes.Title}><b>User Information</b></div>
                        <div><b>{account.firstName + " " + account.lastName}</b></div>
                        <div>{account.personalIdNumber}</div>
                        <div>DOB: {dateFormat(new Date(account.dateOfBirth), "dd/mm/yyyy")}</div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ width: "31%" }}>
                                <div>{account.address}</div>
                                <div>{account.postalCode} {account.city}, {account.country} </div>
                            </div>
                            <div>
                                <div>{account.document.typology} </div>
                                <div>No: {account.document.number}</div>
                                <div>Issued: {account.document.issueDate}</div>
                                <div>Expires: {account.document.expirationDate}</div>
                            </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ width: "31%" }}>
                                <div>{account.email}</div>
                                <div>Phone: {account.cellphone}</div>
                                <div> Fax: {account.fax}</div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <a href={account.signedContractUrl} target="_blank" rel="noopener noreferrer"><button className={classes.YellowButton} style={{ marginBottom: 7, width: 132, marginLeft: 0 }}>CONTRACT</button></a>
                                <a href={account.document.identityDocumentUrl} target="_blank" rel="noopener noreferrer"><button className={classes.YellowButton} style={{ width: 132, marginLeft: 0 }}>ID CARD</button></a>
                            </div>
                        </div>

                    </div>
                    <div className={classes.Title}><b>Manage Account Access</b></div>
                    <div style={{ display: "flex", flexDirection: "row", marginTop: 25 }}>
                        <div style={{ width: "31%" }}>Use the buttons <br /> to the right to <br />manage account <br />access</div>
                        <div style={{ marginRight: 63 }}>
                            <div><b>Status:</b>  {account.state === "ready_for_activation" ? "pending" : account.state}</div>
                            {account.state === "active" ?
                                <>
                                    <div style={{ display: "flex", alignItems: "center" }} onClick={props.suspendUser}>
                                        <i className="fas fa-minus-square fa-3x" style={{ color: "#fd6700", marginRight: 7, cursor: "pointer" }}></i>
                                        <b>SUSPEND</b>
                                    </div>
                                    <div onClick={props.disableUser}>
                                        <i className={["fas fa-times fa-2x", classes.DisabledIcon].join(' ')} style={{ cursor: "pointer" }}></i>
                                        <b>DISABLE</b>
                                    </div>
                                </> :
                                <>
                                    <div style={{ display: "flex", alignItems: "center" }} onClick={props.approveUser}>
                                        <i className="fas fa-check-square fa-3x" style={{ color: "#649177", marginRight: 7, cursor: "pointer" }}></i>
                                        <b>ACTIVATE</b>
                                    </div>
                                    <div onClick={props.disableUser}>
                                        <i className={["fas fa-times fa-2x", classes.DisabledIcon].join(' ')} style={{ cursor: "pointer" }}></i>
                                        <b>DISABLE</b>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
export default PrivateAccount;
